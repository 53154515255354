.graphic-background-bottom-banner-illustration-homepage {
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  .graphic-background-bottom-banner-illustration-homepage .graphic-background-left {
    margin-right: auto;
    min-width: max-content; }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bottom-banner-illustration-homepage .graphic-background-left {
        height: intrinsic; } }
  .graphic-background-bottom-banner-illustration-homepage .graphic-background-right {
    margin-left: auto;
    min-width: max-content; }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bottom-banner-illustration-homepage .graphic-background-right {
        height: intrinsic; } }
  .graphic-background-bottom-banner-illustration-homepage .graphic-background-center {
    min-width: max-content; }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bottom-banner-illustration-homepage .graphic-background-center {
        height: intrinsic; } }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-bg-element-animation],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-animation-bottom],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-animation-center],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-animation-top],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-animation-top-2],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-sm-animation-bottom],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-sm-animation-center],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-sm-animation-top],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-server-sm-animation-top-2],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-animation-cube],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css]:not(.is-animated) [data-animation-path] {
    animation: unset !important; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-cube],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-coin],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-dot],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-vertical-loop-sm],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-vertical-loop],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-up-down],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-paused [data-animation-down-up] {
    animation-play-state: paused; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-bg-element-animation],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-animation-bottom],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-animation-center],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-animation-top],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-animation-top-2],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-sm-animation-bottom],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-sm-animation-center],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-sm-animation-top],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-server-sm-animation-top-2],
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css] [data-animation-cube] {
    opacity: 0; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-bg-element-animation] {
    animation-name: sectionBackgroundFadeTop0-bottom-homepage;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-1] {
      animation-name: sectionBackgroundFadeTop1-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-2] {
      animation-name: sectionBackgroundFadeTop2-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-3] {
      animation-name: sectionBackgroundFadeTop3-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-4] {
      animation-name: sectionBackgroundFadeTop4-bottom-homepage; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation] {
    animation-duration: 1s;
    animation-delay: 0s;
    animation-timing-function: cubic-bezier(0.16, 0, 0, 1);
    animation-fill-mode: forwards; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-animation-bottom] {
      animation-name: serverAnimationBottom-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-animation-center] {
      animation-name: serverAnimationCenter-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-animation-top] {
      animation-name: serverAnimationTop-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-animation-top-2] {
      animation-name: serverAnimationTop2-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-bottom] {
      animation-name: serverSmAnimationBottom-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-center] {
      animation-name: serverSmAnimationCenter-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-top] {
      animation-name: serverSmAnimationTop-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-top-2] {
      animation-name: serverSmAnimationTop2-bottom-homepage; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-dot] {
    animation-duration: 3.20s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-dot][dot-opacity-up] {
      animation-name: dotsLoopOpacity1-bottom-homepage; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-dot][dot-opacity-down] {
      animation-name: dotsLoopOpacity2-bottom-homepage; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube] {
    will-change: transform, opacity;
    animation-name: cubeLoop-bottom-homepage;
    animation-duration: 2.20s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(1) {
      animation-delay: 0.32s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(2) {
      animation-delay: 0.96s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube]:nth-child(3) {
      animation-delay: 1.24s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-1] {
      --cubeStart: 60px;
      --cubeGap: -50px; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-2] {
      --cubeStart: 40px;
      --cubeGap: -70px; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-cube][data-animation-cube-3] {
      --cubeStart: 20px;
      --cubeGap: -70px; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path] {
    will-change: transform, opacity;
    animation-timing-function: linear;
    animation-fill-mode: forwards; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-1] {
      animation-name: pathAnimation1-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-2] {
      animation-name: pathAnimation2-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 2.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-3] {
      animation-name: pathAnimation3-bottom-homepage;
      animation-duration: 1.5s;
      animation-delay: 1.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-4] {
      animation-name: pathAnimation4-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-5] {
      animation-name: pathAnimation5-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 2.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-6] {
      animation-name: pathAnimation6-bottom-homepage;
      animation-duration: 0.6s;
      animation-delay: 2.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-7] {
      animation-name: pathAnimation7-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-8] {
      animation-name: pathAnimation8-bottom-homepage;
      animation-duration: 1.3s;
      animation-delay: 1.08s; }
    .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-path][data-animation-path-9] {
      animation-name: pathAnimation9-bottom-homepage;
      animation-duration: 1s;
      animation-delay: 2.08s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-up-down] {
    will-change: transform, opacity;
    animation-name: upDownReverse-bottom-homepage;
    animation-duration: 3s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-down-up] {
    animation-name: upDown-bottom-homepage;
    animation-duration: 3s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-delay-1] {
    animation-delay: 0.56s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-delay-2] {
    animation-delay: 0.64s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-delay-3] {
    animation-delay: 0.80s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-delay-4] {
    animation-delay: 0.96s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-duration-1] {
    animation-duration: 1s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-duration-2] {
    animation-duration: 2s; }
  .graphic-background-bottom-banner-illustration-homepage[data-animation-css].is-animated [data-animation-duration-3] {
    animation-duration: 3s; }

@keyframes sectionBackgroundFadeTop0-bottom-homepage {
  0% {
    transform: translate3d(0px, 0, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop1-bottom-homepage {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop2-bottom-homepage {
  0% {
    transform: translate3d(0px, 32px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop3-bottom-homepage {
  0% {
    transform: translate3d(0px, 48px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop4-bottom-homepage {
  0% {
    transform: translate3d(0px, 56px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverAnimationBottom-bottom-homepage {
  0% {
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes serverAnimationCenter-bottom-homepage {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverAnimationTop-bottom-homepage {
  0% {
    transform: translate3d(0px, 32px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverAnimationTop2-bottom-homepage {
  0% {
    transform: translate3d(0px, 48px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationBottom-bottom-homepage {
  0% {
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes serverSmAnimationCenter-bottom-homepage {
  0% {
    transform: translate3d(0px, 8px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationTop-bottom-homepage {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationTop2-bottom-homepage {
  0% {
    transform: translate3d(0px, 24px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0, 0px);
    opacity: 1; } }

@keyframes dotsLoopOpacity1-bottom-homepage {
  0%, 100% {
    opacity: 0.2; }
  50% {
    opacity: 1; } }

@keyframes dotsLoopOpacity2-bottom-homepage {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.2; } }

@keyframes cubeLoop-bottom-homepage {
  0% {
    transform: translate3d(0px, var(--cubeStart), 0px);
    opacity: 0; }
  10%, 60% {
    opacity: 1; }
  to {
    transform: translate3d(0px, var(--cubeGap), 0px);
    opacity: 0; } }

@keyframes upDown-bottom-homepage {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(0px, 15px, 0px); } }

@keyframes upDownReverse-bottom-homepage {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(0px, -15px, 0px); } }

@keyframes pathAnimation1-bottom-homepage {
  from {
    stroke-dashoffset: -467; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation2-bottom-homepage {
  from {
    stroke-dashoffset: -158; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation3-bottom-homepage {
  from {
    stroke-dashoffset: -656; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation4-bottom-homepage {
  from {
    stroke-dashoffset: 96; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation5-bottom-homepage {
  from {
    stroke-dashoffset: 96; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation6-bottom-homepage {
  from {
    stroke-dashoffset: -54; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation7-bottom-homepage {
  from {
    stroke-dashoffset: 563; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation8-bottom-homepage {
  from {
    stroke-dashoffset: 358; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation9-bottom-homepage {
  from {
    stroke-dashoffset: 96; }
  to {
    stroke-dashoffset: 0; } }

[dir="rtl"] .graphic-background-bottom-banner-illustration-homepage .graphic-background-left {
  margin-left: auto;
  margin-right: 0;
  transform: scaleX(-1); }

[dir="rtl"] .graphic-background-bottom-banner-illustration-homepage .graphic-background-right {
  margin-left: 0;
  margin-right: auto;
  transform: scaleX(-1); }
